import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import eu from '../images/partners/eu@2x.png'
import kunsthalloslo from '../images/partners/kunsthalloslo@2x.png'
import are from '../images/partners/are.png'
import reading from '../images/partners/reading@2x.png'

const partners = [
  [eu, 'https://ec.europa.eu/programmes/creative-europe/'],
  [kunsthalloslo, 'http://kunsthalloslo.no/'],
  [are, 'http://are-events.org/'],
  [reading, 'http://www.reading.ac.uk/'],
];

class AboutPage extends React.Component {
  render() {
    return (
      <Layout color="black" background="#89C190" classNames="page page--about">
        <SEO title="About" pathname={this.props.location.pathname} description="EuroNoize is a project researching the relationship between art and DiY music scenes in Europe across several platforms." />
        <div>
          <div className="container p-4">

            <div className="flex justify-center flex-wrap">
              <div className="w-full md:w-1/2 md:pr-8">
                <h2 className="text-center">About</h2>
                <div className="md:text-xs">
                  <p>EuroNoize is a project researching the relationship between art and DiY music scenes in Europe across several platforms. At the heart of the EuroNoize project is an alternative music showcase modeled on the Eurovision Song Context, in which musicians will be invited to consider the interplay of local and global identities in representing their country with a specially commissioned song and video. The live event in London will be streamed online and broadcast at partner venues. Viewers will be able to vote digitally for the winning entry. This is accompanied by a conference at the University of Reading, exploring the histories connecting DiY music and the visual arts and investigating the challenges inherent in formalising the informal networks upon which this cultural activity is built. Conference proceedings will lead to a publication in which these themes will be expanded. Finally, the music produced for this live event will be released as a record and disseminated internationally together with the book and an exhibition at Kunsthall Oslo. The project’s explicit aim is to think of and implement strategies and modes of cooperation between art institutions, DiY musicians and internally between various bands across the continent to facilitate the continuation of these often short-lived practices. In doing so, the project investigates the way European co-operation transcends borders and raises questions about the meaning (and sound) of cultural identity in an age of transnational mobility.</p>
                </div>
              </div>
              <div className="w-full md:w-1/2 font-sans md:pl-8 mt-8 md:mt-0">
                <h2 className="text-center">Partners</h2>
                <div className="partners flex flex-col flex-no-wrap justify-center items-center">
                  {partners.map(((p, i) => {
                    return (
                      <a className="partners__link mb-4" href={p[1]} target="_blank" key={i}>
                        <img className="partners__logo" src={p[0]} />
                      </a>
                    )
                  }))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutPage
